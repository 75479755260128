'use client';
import React, { useTransition } from 'react';
import { Button } from '../../_components/ButtonOld';
import { useRouter } from '@/navigation';
import ArrowRightLong from '../../_icons/Arrow-right-long';

export default function EnglishCoursesNotification() {
	const router = useRouter();

	const [isPending, startTransition] = useTransition();

	return (
		<div className="bg-gold-light-32 flex justify-center rounded-4xl px-10 py-10 lg:px-40 lg:py-20">
			<div className="flex flex-col items-center space-y-4 space-x-0 lg:flex-row lg:space-y-0 lg:space-x-8">
				<p className="text-sm leading-6 font-bold lg:text-base lg:leading-8">
					There are more courses available in Estonian
				</p>
				<Button
					loading={isPending}
					onClick={e => {
						e.preventDefault();
						startTransition(() => {
							router.replace('/courses', { locale: 'et' });
						});
					}}
					intent="secondary"
					size="small"
				>
					Switch to Estonian
					<ArrowRightLong />
				</Button>
			</div>
		</div>
	);
}

'use client';
import React from 'react';

import { useTranslations } from 'next-intl';
import { Button } from '../ButtonOld';
import ChevronRight from '../../_icons/Chevron-right';
import ChevronLeft from '../../_icons/Chevron-left';

export const Pagination: React.FC<{
	className?: string;
	onClick: (page: number) => void;
	page: number;
	totalPages: number;
	pending?: boolean;
}> = props => {
	const { className, onClick, page, totalPages, pending } = props;
	const hasNextPage = page < totalPages;
	const hasPrevPage = page > 1;
	const t = useTranslations();

	return (
		<div
			data-pending={pending ? '' : undefined}
			className={`full-w flex flex-row flex-wrap justify-around ${className || ''}`}
		>
			<Button
				size="small"
				intent="secondary"
				variant="outlined"
				className={`${pending ? 'animate-pulse' : ''}`}
				disabled={!hasPrevPage}
				onClick={() => {
					onClick(page - 1);
				}}
			>
				<ChevronLeft />
				{t('previous')}
			</Button>
			<Button
				size="small"
				intent="secondary"
				variant="outlined"
				className={`${pending ? 'animate-pulse' : ''}`}
				disabled={!hasNextPage}
				onClick={() => {
					onClick(page + 1);
				}}
			>
				{t('next')}
				<ChevronRight />
			</Button>
		</div>
	);
};

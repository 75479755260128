import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/node_modules/.pnpm/next-intl@3.26.5_next@15.1.7_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.77.4__react@19.0.0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/node_modules/.pnpm/next-intl@3.26.5_next@15.1.7_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.77.4__react@19.0.0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/node_modules/.pnpm/next-intl@3.26.5_next@15.1.7_react-dom@19.0.0_react@19.0.0__react@19.0.0_sass@1.77.4__react@19.0.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/src/app/(frontend)/_components/Courses/ImageWithFallback.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/src/app/(frontend)/_components/Courses/ListFilters.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/src/app/(frontend)/_components/Courses/ListPagination.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/src/app/(frontend)/[locale]/courses/english-courses-notification.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/data01/virt128836/domeenid/www.kursused.ebs.ee/test.kursused/src/app/(frontend)/[locale]/courses/no-courses-notification.tsx");

import type { Pathnames } from 'next-intl/routing';

export const fallbackLocale = 'en';
export const defaultLocale = 'et';
export const locales = [fallbackLocale, 'et'] as const;
export const localeMap = {
	et: 'EST',
	en: 'ENG',
};
export const localePrefix = 'as-needed';
export const localeDetection = false;

// The `pathnames` object holds pairs of internal
// and external paths, separated by locale.
export const pathnames = {
	'/': '/',

	// If locales use different paths, you can
	// specify each external path per locale.
	'/account/details': {
		en: '/account/details',
		et: '/konto/andmed',
	},
	'/account/orders': {
		en: '/account/orders',
		et: '/konto/tellimused',
	},
	// '/account/orders/[id]': {
	// 	en: '/account/orders/[id]',
	// 	et: '/konto/tellimused/[id]',
	// },

	'/order/[id]': {
		en: '/order/[id]',
		et: '/tellimus/[id]',
	},

	'/cart': {
		en: '/cart',
		et: '/ostukorv',
	},
	'/cart/payment': {
		en: '/cart/payment',
		et: '/ostukorv/maksmine',
	},
	'/checkout': {
		en: '/checkout',
		et: '/kassa',
	},
	'/checkout/participants': {
		en: '/checkout/participants',
		et: '/kassa/osalejad',
	},
	'/checkout/payment': {
		en: '/checkout/payment',
		et: '/kassa/maksmine',
	},
	'/checkout/payment-instalments': {
		en: '/checkout/payment-instalments',
		et: '/kassa/makse-jaotus',
	},
	'/checkout/payment-details': {
		en: '/checkout/payment-details',
		et: '/kassa/maksja',
	},
	'/courses': {
		en: '/courses',
		et: '/kursused',
	},
	'/documents': {
		en: '/documents',
		et: '/dokumendid',
	},

	// todo replace with AuthModal
	'/create-account': {
		en: '/create-account',
		et: '/loo-konto',
	},
	// todo replace with AuthModal
	'/login': {
		en: '/login',
		et: '/sisselogimine',
	},
	'/logout': {
		en: '/logout',
		et: '/logivalja',
	},
	'/order-confirmation': {
		en: '/order-confirmation',
		et: '/tellimuse-kinnitamine',
	},

	'/reset-password': {
		en: '/reset-password',
		et: '/salasona-taastamine',
	},
	// Dynamic params are supported via square brackets
	'/courses/[slug]': {
		en: '/courses/[slug]',
		et: '/kursused/[slug]',
	},
	'/documents/quality-assurance-principles': {
		en: '/documents/quality-assurance-principles',
		et: '/dokumendid/oppekorralduse-alused',
	},
	'/documents/terms-of-use': {
		en: '/documents/terms-of-use',
		et: '/dokumendid/kasutustingimused',
	},
	'/documents/privacy-policy': {
		en: '/documents/privacy-policy',
		et: '/dokumendid/privaatsuspoliitika',
	},
	// '/products/[slug]': {
	// 	en: '/products/[slug]',
	// 	et: '/tooted/[slug]',
	// },

	'/participant/[id]/invitation/[inviteId]': {
		en: '/participant/[id]/invitation/[inviteId]',
		et: '/osaleja/[id]/kutse/[inviteId]',
	},

	'/[slug]': '/[slug]',
	'[slug]': '[slug]',
} satisfies Pathnames<typeof locales>;
export type AppPathnames = keyof typeof pathnames;

export const GOOGLE_TAG_MANAGER_KEY = 'GTM-PCD227PM';

export const GOOGLE_ANALYTICS_KEY = 'G-E4959ZPZCY';

'use client';
import { usePathname, useRouter } from '@/navigation';
import { Course, Courseversion } from '@/payload/payload-types';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@shadcn/components/ui/dropdown-menu';
import { useTranslations } from 'next-intl';
import { Fragment, useOptimistic, useTransition } from 'react';
import { Button } from '../ButtonOld';
import { useParams } from 'next/navigation';
import Tick from '../../_icons/Tick';
import ChevronDown from '../../_icons/Chevron-down';
import { CoursesSearchParams } from '../../[locale]/courses/page';
import { NonNullableCourseversionDuration } from '@/payload/utilities/typescript';

const ALL_MODES: Course['mode'][] = ['blended', 'hybrid', 'inClass', 'online', 'selfPaced'];
// TODO this needs improvement, for filters at least
const ALL_LANGUAGES: Course['language'][] = [
	'onlyEnglish',
	'onlyEstonian',
	'estonianAndEnglish',
	'estonianButSomeMaterialsInEnglish',
];
const ALL_DURATIONS: NonNullableCourseversionDuration[] = ['short', 'medium', 'long'];
export default function ListFilters({
	modes = [],
	languages = [],
	durations = [],
	searchParams,
}: {
	searchParams?: CoursesSearchParams;
	modes?: Course['mode'][];
	languages?: Course['language'][];
	durations?: NonNullableCourseversionDuration[];
}) {
	const router = useRouter();
	const pathname = usePathname();
	const params = useParams();
	const [pending, startTransition] = useTransition();
	const [optimisticModes, setOptimisticModes] = useOptimistic(modes);
	const [optimisticLanguages, setOptimisticLanguages] = useOptimistic(languages);
	const [optimisticDurations, setOptimisticDurations] = useOptimistic(durations);
	const t = useTranslations('Course');

	const getCourseLanguageTranslation = (language: Course['language']): string => {
		const translations = {
			onlyEstonian: t('onlyEstonianShort'),
			onlyEnglish: t('onlyEnglishShort'),
			estonianAndEnglish: t('estonianAndEnglishShort'),
			estonianButSomeMaterialsInEnglish: t('estonianButSomeMaterialsInEnglishShort'),
		};

		return translations[language];
	};
	const sharedButtonTextCss = `max-w-32 grow-0 overflow-hidden text-ellipsis whitespace-nowrap`;
	const hasFilters = Boolean(
		optimisticLanguages?.length || optimisticModes?.length || optimisticDurations?.length,
	);

	const sharedQuery = {
		// reset page if filter changes
		...(searchParams?.page ? { page: 1 } : {}),
	};

	return (
		<div data-pending={pending ? '' : undefined} className="flex flex-wrap gap-2">
			<Fragment>
				{/* modes */}
				<DropdownMenu>
					<DropdownMenuTrigger asChild={true}>
						<Button
							size="small"
							intent={`${optimisticModes?.length ? 'secondary' : 'tertiary'}`}
							variant="solid"
							className={`${pending ? 'animate-pulse' : ''}`}
						>
							<span className={sharedButtonTextCss}>
								{optimisticModes?.length
									? optimisticModes
											.map(mode =>
												t('courseversion-mode-short', {
													mode,
												}),
											)
											.join(', ')
									: t('method')}
							</span>

							<ChevronDown className="size-4" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent>
						{ALL_MODES.map(mode => {
							const isSelected = optimisticModes?.includes(mode);

							return (
								<DropdownMenuItem
									key={`list-filters-mode-${mode}`}
									className={`${isSelected ? 'bg-gold-light hover:bg-gold text-white' : ''}`}
									onClick={() => {
										const newModes = !optimisticModes.includes(mode)
											? [...optimisticModes, mode]
											: optimisticModes.filter(g => g !== mode);

										startTransition(() => {
											setOptimisticModes(newModes.sort());
											// @ts-expect-error -- TypeScript will validate that only known `params`
											// are used in combination with a given `pathname`. Since the two will
											// always match for the current route, we can skip runtime checks.
											router.push({
												pathname,
												params,
												query: {
													...searchParams,
													...sharedQuery,
													modes: newModes,
												},
											});
										});
									}}
								>
									<span className={`${isSelected ? '' : 'invisible'} `}>
										<Tick color="white" />
									</span>
									{t('courseversion-mode-short', {
										mode,
									})}
								</DropdownMenuItem>
							);
						})}
					</DropdownMenuContent>
				</DropdownMenu>

				{/* languages */}
				<DropdownMenu>
					<DropdownMenuTrigger asChild={true}>
						<Button
							size="small"
							intent={`${optimisticLanguages?.length ? 'secondary' : 'tertiary'}`}
							className={`${pending ? 'animate-pulse' : ''}`}
						>
							<span className={sharedButtonTextCss}>
								{optimisticLanguages?.length
									? optimisticLanguages
											.map(language => getCourseLanguageTranslation(language))
											.join(', ')
									: t('language')}
							</span>

							<ChevronDown className="size-4" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent>
						{/* <DropdownMenuLabel>My Account</DropdownMenuLabel> */}
						{/* <DropdownMenuSeparator /> */}
						{ALL_LANGUAGES.map(language => {
							const isSelected = optimisticLanguages?.includes(language);

							return (
								<DropdownMenuItem
									key={`list-filters-language-${language}`}
									className={`${isSelected ? 'bg-gold-light hover:bg-gold text-white' : ''}`}
									onClick={() => {
										const newLanguages = !optimisticLanguages.includes(language)
											? [...optimisticLanguages, language]
											: optimisticLanguages.filter(g => g !== language);

										startTransition(() => {
											setOptimisticLanguages(newLanguages.sort());
											// @ts-expect-error -- TypeScript will validate that only known `params`
											// are used in combination with a given `pathname`. Since the two will
											// always match for the current route, we can skip runtime checks.
											router.push({
												pathname,
												params,
												query: {
													...searchParams,
													...sharedQuery,
													languages: newLanguages,
												},
											});
										});
									}}
								>
									<span className={`${isSelected ? '' : 'invisible'} `}>
										<Tick color="white" />
									</span>
									{getCourseLanguageTranslation(language)}
								</DropdownMenuItem>
							);
						})}
					</DropdownMenuContent>
				</DropdownMenu>

				{/* Duration */}
				<DropdownMenu>
					<DropdownMenuTrigger asChild={true}>
						<Button
							size="small"
							intent={`${optimisticDurations?.length ? 'secondary' : 'tertiary'}`}
							className={`${pending ? 'animate-pulse' : ''}`}
						>
							<span className={sharedButtonTextCss}>
								{optimisticDurations?.length
									? optimisticDurations
											.map(duration =>
												t('courseversion-duration-short', {
													duration,
												}),
											)
											.join(', ')
									: t('duration')}
							</span>

							<ChevronDown className="size-4" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent>
						{/* <DropdownMenuLabel>My Account</DropdownMenuLabel> */}
						{/* <DropdownMenuSeparator /> */}
						{ALL_DURATIONS.map(duration => {
							const isSelected = optimisticDurations?.includes(duration);

							return (
								<DropdownMenuItem
									key={`list-filters-duration-${duration}`}
									className={`${isSelected ? 'bg-gold-light hover:bg-gold text-white' : ''}`}
									onClick={() => {
										const newDurations = !optimisticDurations.includes(duration)
											? [...optimisticDurations, duration]
											: optimisticDurations.filter(g => g !== duration);

										startTransition(() => {
											setOptimisticDurations(newDurations.sort());
											// @ts-expect-error -- TypeScript will validate that only known `params`
											// are used in combination with a given `pathname`. Since the two will
											// always match for the current route, we can skip runtime checks.
											router.push({
												pathname,
												params,
												query: {
													...searchParams,
													...sharedQuery,
													durations: newDurations,
												},
											});
										});
									}}
								>
									<span className={`${isSelected ? '' : 'invisible'} `}>
										<Tick color="white" />
									</span>
									{t('courseversion-duration-short', {
										duration,
									})}
								</DropdownMenuItem>
							);
						})}
					</DropdownMenuContent>
				</DropdownMenu>

				{hasFilters && (
					<Button
						variant="ghost"
						size="small"
						className="ml-2 underline"
						onClick={() => {
							startTransition(() => {
								setOptimisticLanguages([]);
								setOptimisticModes([]);
								// @ts-expect-error -- TypeScript will validate that only known `params`
								// are used in combination with a given `pathname`. Since the two will
								// always match for the current route, we can skip runtime checks.
								router.push({
									pathname,
									params,
								});
							});
						}}
					>
						{t('clear-filters')}
					</Button>
				)}
			</Fragment>
		</div>
	);
}
